import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hi, I’m Ben, a senior software engineer in Greenville, SC for `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/"
      }}>{`Gatsby`}</a>{`. In my free time I enjoy hiking, spending time with my wife and children, and learning to play the banjo.`}</p>
    <p>{`I create fun, innovative, accessible, and fast websites. I try to leave every bit I code I touch more readable, performant and accessible than I found it.`}</p>
    <p>{`These are the accomplishments I'm most proud of:`}</p>
    <ul>
      <li parentName="ul">{`Speaking at the first ever Gatsby Days conference on `}<a parentName="li" {...{
          "href": "https://benrobertson.io/general/trends-in-modern-web-development"
        }}>{`trends in modern web development`}</a></li>
      <li parentName="ul">{`Wrote and marketed an `}<a parentName="li" {...{
          "href": "https://benrobertson.io/courses/common-accessibility-mistakes/"
        }}>{`email course on web accessibility`}</a>{` to 500+ developers`}</li>
      <li parentName="ul">{`Spearheaded the agency use of pattern libraries at my first job and wrote about it for `}<a parentName="li" {...{
          "href": "https://css-tricks.com/build-style-guide-straight-sass/"
        }}>{`CSS-Tricks`}</a>{`.`}</li>
      <li parentName="ul">{`Learned Drupal 7 on the fly, developed an `}<a parentName="li" {...{
          "href": "https://www.upandup.agency/awards/upup-wins-ucda-excellence-award-university-kentucky-website"
        }}>{`award-winning Drupal 7 site for the University of Kentucky`}</a>{` and became lead Drupal developer.`}</li>
      <li parentName="ul">{`Led the team at my first job in documenting front-end and server configuration best practices to streamline current development and help onboard new developers.`}</li>
    </ul>
    <hr />
    <h2 {...{
      "id": "speaking"
    }}>{`Speaking`}</h2>
    <h3 {...{
      "id": "gatsby-at-mediacurrent"
    }}>{`Gatsby at Mediacurrent`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Event:`}</strong>{` Gatsby for Agencies and Teams: Better, Faster, Smarter Webinar`}</li>
      <li parentName="ul"><strong parentName="li">{`Date:`}</strong>{` March 2019`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=jRntYO4186Y"
        }}>{`Recording`}</a></li>
    </ul>
    <p>{`In this webinar I shared a little bit about how we've introduced Gatsby into our workflow at Mediacurrent and how things have changed as a result.`}</p>
    <p>{`I discuss:`}</p>
    <ul>
      <li parentName="ul">{`how Gatsby has changed our development process`}</li>
      <li parentName="ul">{`how Gatsby has influenced the types of projects we are able to take on`}</li>
      <li parentName="ul">{`how even things like hiring and advancement are changing as well`}</li>
    </ul>
    <h3 {...{
      "id": "move-fast-dont-break-things-trends-in-modern-web-dev"
    }}>{`Move Fast, Don’t Break Things: Trends in Modern Web Dev`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Event`}</strong>{`: Gatsby Days`}</li>
      <li parentName="ul"><strong parentName="li">{`Date`}</strong>{`: December 2018`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gatsbyjs.com/gatsby-days-mediacurrent-ben"
        }}>{`Recording`}</a></li>
    </ul>
    <p>{`In this talk at the first Gatsby Days event, I discuss how trends in modern web development and moving away from "CMS-Centered Development". I share a case study from our first decoupled Drupal and Gatsby project at Mediacurrent.`}</p>
    <p>{`I also discuss the different ways decoupling can change things at an organization, including the development process, but even beyond into hiring and client diversity.`}</p>
    <h3 {...{
      "id": "common-accessibility-mistakes-and-how-to-avoid-them"
    }}>{`Common Accessibility Mistakes and How to Avoid Them`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Event`}</strong>{`: DrupalCamp Asheville`}</li>
      <li parentName="ul"><strong parentName="li">{`Date`}</strong>{`: July 2018`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://benrobertson.io/accessibility/common-accessibility-mistakes-and-how-to-avoid-them"
        }}>{`Recording`}</a></li>
    </ul>
    <p>{`I discussed four of my own homegrown principles for developing accessible websites:`}</p>
    <ol>
      <li parentName="ol">{`Web Design is more than graphic design`}</li>
      <li parentName="ol">{`Be ASAP: As Semantic As Possible`}</li>
      <li parentName="ol">{`Websites should look good naked`}</li>
      <li parentName="ol">{`Talk to your computer (Use ARIA attributes)`}</li>
    </ol>
    <p>{`I also covered 10 common accessibility mistakes that I come across (and that I’ve personally made), and how to fix them.`}</p>
    <h3 {...{
      "id": "component-based-development"
    }}>{`Component Based Development`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Event`}</strong>{`: DrupalCon Nashville`}</li>
      <li parentName="ul"><strong parentName="li">{`Date`}</strong>{`: April 2018`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://benrobertson.io/announcements/component-driven-development-ui-patterns"
        }}>{`Overview`}</a></li>
    </ul>
    <p>{`This full day training session starts with an overview of component-driven development, typical workflows, and benefits for teams and stakeholders, and then we dive deep into the workflow by building out a sample Drupal theme using the UI Patterns approach. We cover:`}</p>
    <ul>
      <li parentName="ul">{`An in-depth look at creating patterns including markup, styles, and behaviors`}</li>
      <li parentName="ul">{`Composing complex UI patterns by nesting simple patterns in larger patterns`}</li>
      <li parentName="ul">{`Integrating front-end patterns with Drupal using the UI Patterns module to simplify the process and preserve Drupal’s baked in goodies`}</li>
      <li parentName="ul">{`Advanced workflows with Sass, a Drupal 8 theme generator, incorporating ES6/Babel with patterns`}</li>
    </ul>
    <hr />
    <h2 {...{
      "id": "experience"
    }}>{`Experience`}</h2>
    <h3 {...{
      "id": "gatsby"
    }}>{`Gatsby`}</h3>
    <p><strong parentName="p">{`Senior Software Engineer, Customer Success`}</strong>{` | January 2020 - Present`}</p>
    <p>{`Helping customers succeed using Gatsby beyond their wildest dreams!`}</p>
    <h3 {...{
      "id": "mediacurrent"
    }}>{`Mediacurrent`}</h3>
    <p><strong parentName="p">{`Senior Frontend Developer`}</strong>{` | December 2017 - January 2020`}</p>
    <ul>
      <li parentName="ul">{`Lead front end development for complex architectures utilizing `}<strong parentName="li">{`Gatsby, Drupal, Auth0, and Apollo Server`}</strong>{`.`}</li>
      <li parentName="ul">{`Define requirements, `}<strong parentName="li">{`estimate`}</strong>{` new features, `}<strong parentName="li">{`review`}</strong>{` pull requests and provide `}<strong parentName="li">{`constructive feedback`}</strong>{` for other front end developers.`}</li>
      <li parentName="ul">{`Refactor a complex `}<strong parentName="li">{`React/Redux`}</strong>{` application for a major insurance company, increasing stability and maintainability of code and introduced unit testing strategy to `}<strong parentName="li">{`increase test coverage by 1370%`}</strong>{`.`}</li>
      <li parentName="ul">{`Perform accessibility remediation for enterprise clients to bring them into `}<strong parentName="li">{`WCAG 2.1 AA`}</strong>{` compliance.`}</li>
      <li parentName="ul">{`Speak and write effectively on various front end development topics including `}<a parentName="li" {...{
          "href": "https://www.mediacurrent.com/blog/5-website-accessibility-checkers"
        }}>{`accessibility`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.mediacurrent.com/blog/join-our-drupalcon-training-session-component-based-development-using-ui-patterns/"
        }}>{`component-driven development`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.mediacurrent.com/blog/myth-inaccessible-react"
        }}>{`React`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=QiocnDGnKfs&index=6&t=0s&list=PLCU2qJekvcN1wzgVKymbjbGtZ3VgAWOdj"
        }}>{`Gatsby`}</a>{`, and `}<a parentName="li" {...{
          "href": "https://www.mediacurrent.com/blog/improve-your-remote-work-productivity-shutdown-ritual"
        }}>{`remote work`}</a>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "upup-agency"
    }}>{`Up&Up Agency`}</h3>
    <p><strong parentName="p">{`Front End Developer`}</strong>{` `}{`|`}{` February 2015 - November 2017`}</p>
    <h4 {...{
      "id": "key-responsibilities"
    }}>{`Key Responsibilities:`}</h4>
    <ul>
      <li parentName="ul">{`Transform static design comps into fast, accessible, interactive, maintainable HTML/CSS/Javascript`}</li>
      <li parentName="ul">{`Develop efficient, user-friendly backend content management systems`}</li>
      <li parentName="ul">{`Integrate front-end development products with content management systems.`}</li>
    </ul>
    <h4 {...{
      "id": "accomplishments"
    }}>{`Accomplishments:`}</h4>
    <ul>
      <li parentName="ul">{`Spearheaded the agency use of pattern libraries and wrote about it for `}<a parentName="li" {...{
          "href": "https://css-tricks.com/build-style-guide-straight-sass/"
        }}>{`CSS-Tricks`}</a></li>
      <li parentName="ul">{`Developed an internal `}<a parentName="li" {...{
          "href": "/front-end/css-standards"
        }}>{`CSS style guide`}</a>{` for standardizing our approach to CSS/Sass for across all projects.`}</li>
      <li parentName="ul">{`Learned Drupal 7 on the fly, developed an `}<a parentName="li" {...{
          "href": "https://www.upandup.agency/awards/upup-wins-ucda-excellence-award-university-kentucky-website"
        }}>{`award-winning Drupal 7 site for the University of Kentucky`}</a>{` and became lead Drupal developer.`}</li>
      <li parentName="ul">{`Developed documentation templates currently in use in all agency projects.`}</li>
      <li parentName="ul">{`Streamlined build tool setup with Webpack, npm scripts and Grunt.`}</li>
      <li parentName="ul">{`Led the agency adoption of SVG and automated build tools.`}</li>
      <li parentName="ul">{`Led the team in documenting front-end and server configuration `}<a parentName="li" {...{
          "href": "http://docs.upupdev.net"
        }}>{`best practices`}</a>{` to streamline current development and help onboard new developers.`}</li>
    </ul>
    <h4 {...{
      "id": "projects"
    }}>{`Projects:`}</h4>
    <h5 {...{
      "id": "university-of-kentucky-help-center--2017"
    }}>{`University of Kentucky Help Center | 2017`}</h5>
    <ul>
      <li parentName="ul">{`A knowledge base for students, alumni, and faculty to find the information they need to succeed.`}</li>
      <li parentName="ul">{`I used CakePHP as a caching layer between a React.js front end the Salesforce Knowledge Base API.`}</li>
    </ul>
    <h5 {...{
      "id": "university-of-north-carolina--january-2017"
    }}><a parentName="h5" {...{
        "href": "http://more.unc.edu/"
      }}>{`University of North Carolina`}</a>{` | January 2017`}</h5>
    <ul>
      <li parentName="ul">{`A simple Wordpress microsite for the Friday Center at the University of North Carolina at Chapel Hill.`}</li>
    </ul>
    <h5 {...{
      "id": "university-of-kentucky-academic-exploration-tool--november-2015"
    }}><a parentName="h5" {...{
        "href": "http://www.uky.edu/academics/"
      }}>{`University of Kentucky Academic Exploration Tool`}</a>{` | November 2015`}</h5>
    <ul>
      <li parentName="ul">{`A tool to help current and prospective students discover their majors and programs based on their interests or career aspirations.`}</li>
      <li parentName="ul">{`I developed the front-end of this project as well as the majority of the Drupal site-building/backend.`}</li>
    </ul>
    <h5 {...{
      "id": "design--upup--october-2016"
    }}>{`Design @ Up`}{`&`}{`Up | October 2016`}</h5>
    <ul>
      <li parentName="ul">{`A custom Drupal 8 application that integrates with `}<a parentName="li" {...{
          "href": "https://bugherd.com/"
        }}>{`Bugherd`}</a>{`.`}</li>
      <li parentName="ul">{`Our team uses it to upload jpg or png comps to a client project, and the client can use Bugherd to markup the comps with feedback.`}</li>
      <li parentName="ul">{`It provides an intuitive way for clients to see their comps in the browser and a much easier way for us to collect and keep track of feedback.`}</li>
    </ul>
    <h5 {...{
      "id": "post-an-internal-content-calendar-tool--january-2016"
    }}>{`Post, an internal content calendar tool | January 2016`}</h5>
    <ul>
      <li parentName="ul">{`I built an internal tool to help our account management team, our content strategy time, and our clients manage the content strategies that we are developing for them.`}</li>
      <li parentName="ul">{`I developed the front end and backend.`}</li>
    </ul>
    <h4 {...{
      "id": "technologies-used"
    }}>{`Technologies Used:`}</h4>
    <ul>
      <li parentName="ul">{`HTML5`}</li>
      <li parentName="ul">{`Sass`}</li>
      <li parentName="ul">{`CSS`}</li>
      <li parentName="ul">{`SVG`}</li>
      <li parentName="ul">{`Javascript`}</li>
      <li parentName="ul">{`React.js`}</li>
      <li parentName="ul">{`Grunt`}</li>
      <li parentName="ul">{`Webpack`}</li>
      <li parentName="ul">{`Drupal`}</li>
      <li parentName="ul">{`PHP`}</li>
      <li parentName="ul">{`jQuery`}</li>
      <li parentName="ul">{`Wordpress`}</li>
      <li parentName="ul">{`CakePHP`}</li>
      <li parentName="ul">{`Python`}</li>
      <li parentName="ul">{`Django`}</li>
    </ul>
    <h3 {...{
      "id": "freelance"
    }}>{`Freelance`}</h3>
    <p><strong parentName="p">{`Wordpress / Frontend Developer`}</strong>{` `}{`|`}{` October 2014 - June 2015`}</p>
    <ul>
      <li parentName="ul">{`Designed and implemented custom dashboards for a family of new and used car dealerships to provide high- level sales metrics for each dealership and the business as a whole, as well as monitoring the ROI of paid advertising campaigns to the upper management.`}</li>
      <li parentName="ul">{`Developed and updated custom responsive Wordpress themes.`}</li>
      <li parentName="ul">{`Technologies Used: HTML, CSS, SCSS, Less, Javascript, jQuery, PHP, Wordpress, Grunt, custom dashboards.`}</li>
    </ul>
    <h3 {...{
      "id": "gannett"
    }}>{`Gannett`}</h3>
    <p><strong parentName="p">{`Digital Strategist`}</strong>{` `}{`|`}{` October 2013 - September 2014`}</p>
    <ul>
      <li parentName="ul">{`Utilized Polk automotive market data, Scarborough, Nielsen, and other marketing resources to help plan the sales strategy for automotive advertising sales teams at both our Greenville and Asheville newspaper locations.`}</li>
      <li parentName="ul">{`Planned and executed strategic integrated marketing campaigns—paid search, SEO, social media marketing, email marketing, print, and targeted digital display—for franchise and independent automotive dealerships.`}</li>
      <li parentName="ul">{`Developed a web analytics consulting process to help qualify new clients and increase revenue, achieving year- over-year revenue growth of 22%.`}</li>
    </ul>
    <h3 {...{
      "id": "chicago-tribune"
    }}>{`Chicago Tribune`}</h3>
    <p><strong parentName="p">{`Digital Advertising Sales Consultant`}</strong>{` `}{`|`}{` February 2012 - September 2013`}</p>
    <ul>
      <li parentName="ul">{`Collaborated with marketing directors, property managers, and leasing directors to plan, budget, and execute successful multi-platform advertising campaigns for apartment communities in Chicago’s western suburbs.`}</li>
      <li parentName="ul">{`Developed advertising plans utilizing the Chicago Tribune Media Group and Apartments.com portfolio, including SEO services, web development, paid search, digital display, mobile, print, and pre-print advertising.`}</li>
      <li parentName="ul">{`Negotiated with new and existing clients to protect profit margins while securing customer satisfaction. Managed $800,000 of revenue from local and national Real Estate Advertisers, achieving 15% growth.`}</li>
    </ul>
    <hr />
    <h2 {...{
      "id": "side-projects--volunteering"
    }}>{`Side Projects / Volunteering`}</h2>
    <h3 {...{
      "id": "engaged-encounter"
    }}>{`Engaged Encounter`}</h3>
    <p>{`My lovely wife and I are presenting couples for `}<a parentName="p" {...{
        "href": "http://www.cee-sc.org/"
      }}>{`South Carolina Catholic Engaged Encounter`}</a>{`. We provide weekend-long marriage preparation retreats for engaged couples. I am also the treasurer.`}</p>
    <h3 {...{
      "id": "second-nature-journal"
    }}>{`Second Nature Journal`}</h3>
    <p><strong parentName="p">{`2013 - 2017`}</strong></p>
    <p>{`I started and ran (with the help of some friends) an online journal for critical thinking about technology and new media in light of the Christian tradition, `}<a parentName="p" {...{
        "href": "https://secondnaturejournal.com/"
      }}>{`Second Nature`}</a>{`.`}</p>
    <h3 {...{
      "id": "iistc"
    }}>{`IISTC`}</h3>
    <p><strong parentName="p">{`2013 - 2017`}</strong></p>
    <p>{`The IISTC (`}<a parentName="p" {...{
        "href": "https://iistc.org/"
      }}>{`International Institute for the Study of Technology and Christianity`}</a>{`) is the non-profit we started to publish Second Nature. I'm was executive director.`}</p>
    <hr />
    <h2 {...{
      "id": "about-this-site"
    }}>{`About this site`}</h2>
    <p>{`This site is a `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org"
      }}>{`Gatsby`}</a>{` site I designed and built using `}<a parentName="p" {...{
        "href": "https://theme-ui.com/"
      }}>{`theme-ui`}</a>{`. The type is set in `}<a parentName="p" {...{
        "href": "https://github.com/adobe-fonts/source-sans-pro"
      }}>{`Adobe Source Sans Pro`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      